import * as yup from "yup";
import {addMethod, string} from "yup";
import {CountryCode, validatePhoneNumberLength} from "libphonenumber-js";
import {ContactCategory} from "../DeliveryAddress/components/interfaces";
import {t} from "i18next";
import {
  hasOnlyLatinValues,
  validatePhoneNumber,
  validatePhoneNumberByCountryCode,
} from "../../../utils/string.utils";

declare module "yup" {
  interface StringSchema {
    latinCharactersOnly(message?: string): StringSchema;

    checkPhoneNumberCountry(
      countryCode?: string | null | undefined,
      message?: string | null | undefined,
    ): StringSchema;

    validatePhoneNumber(message?: string | null | undefined): StringSchema;
  }
}

addMethod(
  string,
  "latinCharactersOnly",
  function latinCharactersOnly(
    message = t("validation.address.latinOnlyRequired"),
  ) {
    return this.when("countryCustomValues.is_latin_only_supported", {
      is: "true",
      then: this.test({
        name: "latinOnly",
        message: message,
        test: (value) => {
          return hasOnlyLatinValues(value);
        },
      }),
    });
  },
);

addMethod(
  string,
  "checkPhoneNumberCountry",
  function checkPhoneNumberCountry(
    countryCode?: string | null | undefined,
    message = t("validation.address.unsupportedPhoneNumberCountry"),
  ) {
    if (!countryCode) {
      return this;
    }
    return this.test({
      name: "checkPhoneNumberCountry",
      message: message,
      test: (value) => {
        return validatePhoneNumberByCountryCode(
          value,
          countryCode as CountryCode,
        );
      },
    });
  },
);

addMethod(
  string,
  "validatePhoneNumber",
  function isValidPhoneNumber(
    message = t("validation.phoneVerification.invalidNumber"),
  ) {
    return this.test({
      name: "validPhoneNumber",
      message: message,
      test: (value) => {
        return validatePhoneNumber(value);
      },
    });
  },
);

const checkNumberLength = (value: string, code: string | undefined) => {
  return validatePhoneNumberLength(value, code as CountryCode);
};

export const getDeliveryAddressValidationSchema = (
  form: string,
  t: any,
  countryType?: string | null,
  countryCode?: string | null | undefined,
  contactCategory?: ContactCategory | null,
) => {
  const isUSMode = countryType === "US";
  const isBusiness = contactCategory
    ? contactCategory === ContactCategory.Business
    : false;

  const schema =
    form === "toAddress"
      ? yup.object().shape({
          toAddressFirstName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.firstName"))
            .max(50, t("validation.deliveryAddress.firstNameLength"))
            .latinCharactersOnly(),
          toAddressLastName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.lastName"))
            .max(50, t("validation.deliveryAddress.lastNameLength"))
            .latinCharactersOnly(),
          toAddressPhoneNumber: yup
            .string()
            .required(t("validation.phoneVerification.requiredNumber"))
            .test(
              "is-short",
              t("validation.phoneVerification.shortNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLength(value, countryCode || "US") !==
                    "TOO_SHORT"
                  );
                }
              },
            )
            .test(
              "is-long",
              t("validation.phoneVerification.longNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLength(value, countryCode || "US") !== "TOO_LONG"
                  );
                }
              },
            )
            .latinCharactersOnly()
            .validatePhoneNumber()
            .checkPhoneNumberCountry(countryCode),
          toAddressEmail: yup
            .string()
            .email(t("validation.common.emailFormat"))
            .max(256, t("validation.common.emailLengthMax"))
            .required(t("validation.common.emailRequired"))
            .latinCharactersOnly(),
          toAddressCountryCode: yup
            .string()
            .required(t("validation.address.countryRequired")),
          toAddressRegionCode: yup
            .string()
            .required(t("validation.address.regionRequired"))
            .max(100, t("validation.address.regionLength")),
          toAddressDistrict: !isUSMode
            ? yup
                .string()
                .trim()
                .max(60, t("validation.address.districtLength"))
                .latinCharactersOnly()
            : yup.string(),
          toAddressCity: yup
            .string()
            .trim()
            .required(t("validation.address.cityRequired"))
            .max(60, t("validation.address.cityLength"))
            .latinCharactersOnly(),
          toAddressPostalCode: yup
            .string()
            .trim()
            .required(t("validation.address.postalCodeRequired"))
            .min(4, t("validation.address.postalCodeLengthMin"))
            .max(10, t("validation.address.postalCodeLength"))
            .latinCharactersOnly(),
          toAddressStreetName: !isUSMode
            ? yup
                .string()
                .trim()
                .required(t("validation.address.streetNameRequired"))
                .max(60, t("validation.address.streetNameLength"))
                .latinCharactersOnly()
            : yup
                .string()
                .trim()
                .required(t("validation.address.addressLineRequired"))
                .max(120, t("validation.address.addressLineLength"))
                .latinCharactersOnly(),
          toAddressHouseNumber: !isUSMode
            ? yup
                .string()
                .trim()
                .required(t("validation.address.houseNumberRequired"))
                .max(10, t("validation.address.houseNumberLength"))
                .latinCharactersOnly()
            : yup.string(),
          toAddressApartment: !isUSMode
            ? yup
                .string()
                .trim()
                .max(10, t("validation.address.apartmentLength"))
                .latinCharactersOnly()
            : yup
                .string()
                .trim()
                .max(120, t("validation.address.addressLine2Length"))
                .latinCharactersOnly(),
          toAddressCompanyName: isBusiness
            ? yup
                .string()
                .trim()
                .required(t("validation.deliveryAddress.companyNameRequired"))
                .max(100, t("validation.deliveryAddress.companyNameLength"))
                .latinCharactersOnly()
            : yup.string(),
          toAddressContactCategory: yup.string().nullable(),
          toAddressWithoutApartmentConfirmed: yup.boolean().nullable(),
        })
      : form === "pickupLocation"
      ? yup.object().shape({
          pickupLocationFirstName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.firstName"))
            .max(50, t("validation.deliveryAddress.firstNameLength"))
            .latinCharactersOnly(),
          pickupLocationLastName: yup
            .string()
            .trim()
            .required(t("validation.deliveryAddress.lastName"))
            .max(50, t("validation.deliveryAddress.lastNameLength"))
            .latinCharactersOnly(),
          pickupLocationPhoneNumber: yup
            .string()
            .required(t("validation.phoneVerification.requiredNumber"))
            .test(
              "is-short",
              t("validation.phoneVerification.shortNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLength(value, countryCode || "US") !==
                    "TOO_SHORT"
                  );
                }
              },
            )
            .test(
              "is-long",
              t("validation.phoneVerification.longNumber"),
              (value: string | undefined): any => {
                if (value) {
                  return (
                    checkNumberLength(value, countryCode || "US") !== "TOO_LONG"
                  );
                }
              },
            )
            .latinCharactersOnly()
            .validatePhoneNumber()
            .checkPhoneNumberCountry(countryCode),
          pickupLocationCountryCode: yup
            .string()
            .required(t("validation.address.countryRequired")),
          pickupLocationContactAddressId: yup
            .string()
            .required(t("validation.deliveryAddress.pickupLocationRequired"))
            .nullable(),
          pickupLocationEmail: yup
            .string()
            .email(t("validation.common.emailFormat"))
            .max(256, t("validation.common.emailLengthMax"))
            .required(t("validation.common.emailRequired"))
            .latinCharactersOnly(),
          pickupLocationCompanyName: isBusiness
            ? yup
                .string()
                .trim()
                .required(t("validation.deliveryAddress.companyNameRequired"))
                .max(100, t("validation.deliveryAddress.companyNameLength"))
                .latinCharactersOnly()
            : yup.string(),
          pickupLocationContactCategory: yup.string().nullable(),
        })
      : {};
  return schema;
};
