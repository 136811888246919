export const parseGeocodeResults = (
  result: google.maps.places.PlaceResult | null,
) => {
  if (!result) {
    return null;
  }

  const types: any = {
    home: ["street_number", "premise", "subpremise"],
    postal_code: ["postal_code"],
    street: [
      "street_address",
      "route",
      "point_of_interest",
      "intersection",
      "airport",
      "park",
      "point_of_interest",
      "natural_feature",
      "plus_code",
    ],
    state: ["administrative_area_level_1"],
    region: [
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
      "administrative_area_level_6",
      "administrative_area_level_7",
    ],
    city: ["locality"],
    country: ["country"],
  };

  const address: any = {
    home: [],
    postal_code: [],
    street: [],
    stateCode: [],
    stateName: [],
    region: [],
    city: [],
    countryCode: [],
    countryName: [],
  };

  result.address_components?.forEach((component: any) => {
    for (const type in types) {
      if (types[type].includes(component.types[0])) {
        if (type === "country" || type === "state") {
          address[`${type}Code`].push(component.short_name);
          address[`${type}Name`].push(component.long_name);
        } else {
          address[type].push(component.long_name);
        }
      }
    }
  });

  const addressLine =
    address.home.length > 0 && address.street.length > 0
      ? `${address.home} ${address.street.join(", ")}`
      : `${address.region.join(", ")}`;
  const addressLineEU = address.street.join(", ");
  const houseNumber = address.home.join(" ");
  const city = address.city.join(", ");
  const postalCode = address.postal_code.toString();
  const district = address.region.toString();
  const stateCode = address.stateCode.toString();
  const stateName = address.stateName.toString();
  const countryCode = address.countryCode.toString();
  const countryName = address.countryName.toString();
  const latitude = result.geometry?.location?.lat();
  const longitude = result.geometry?.location?.lng();

  return {
    addressLine,
    addressLineEU,
    houseNumber,
    city,
    postalCode,
    district,
    stateCode,
    stateName,
    countryCode,
    countryName,
    latitude,
    longitude,
  };
};
