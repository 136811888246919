import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useCallback} from "react";
import {toastError} from "../../../common/utils/toastMessages";

export const useCancelPickupWorkflow = (
  workflowId: string | null | undefined,
) => {
  if (!workflowId) return {};

  const [runWorkflow, {isLoading, isError, error}] =
    useWorkflowExecutionMutation();

  const cancelPickup = useCallback(
    async ({orderId}: any) => {
      if (!workflowId || !orderId) return;

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {
          variables: {
            orderId,
          },
        },
      };

      const workflowResult: any = await runWorkflow(executeWorkflowApiArgs);

      if (!workflowResult?.data?.outputs?.cancelPickupResponse) {
        toastError(workflowResult);
        return null;
      }

      return workflowResult?.data?.outputs?.cancelPickupResponse;
    },
    [runWorkflow, workflowId],
  );

  return {cancelPickup, isLoading, isError, error};
};
