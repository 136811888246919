import {
  CountryCode,
  isValidPhoneNumber,
  ParsedNumber,
  parseNumber,
} from "libphonenumber-js";
import stringSimilarity from "string-similarity";
import {StateDto} from "../modules/features/states/states-api";

export const trimStringValues = (valuesObj: any) => {
  if (!valuesObj) return valuesObj;

  const clonedObj = structuredClone(valuesObj);

  Object.keys(clonedObj).forEach((k) => {
    if (typeof clonedObj[k] === "string") {
      clonedObj[k] = clonedObj[k].trim();
    } else {
      clonedObj[k] = trimStringValues(clonedObj[k]);
    }
  });

  return clonedObj;
};

const latinRegex = /^[\u0020-\u007E]*$/; // Matches printable ASCII characters

// Returns `false` if any string within the value contains non-Latin characters.
export const hasOnlyLatinValues = (value: any): boolean => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === "string") {
    return latinRegex.test(value);
  }
  if (Array.isArray(value)) {
    return value.every((item) => hasOnlyLatinValues(item));
  }
  if (typeof value === "object") {
    return Object.values(value).every((item) => hasOnlyLatinValues(item));
  }

  return true;
};

export const validatePhoneNumber = (phoneNumberValue: string | undefined) => {
  if (phoneNumberValue) {
    return isValidPhoneNumber(phoneNumberValue);
  }
  return false;
};

export const validatePhoneNumberByCountryCode = (
  phoneNumberValue: string | undefined,
  countryCode: string | undefined,
) => {
  if (phoneNumberValue && countryCode) {
    const phoneNumberParseResult = parseNumber(
      phoneNumberValue,
      countryCode as CountryCode,
    ) as ParsedNumber;

    if (!phoneNumberParseResult?.country) return true; // skip invalid phone number

    return (
      phoneNumberParseResult && phoneNumberParseResult.country === countryCode
    );
  }
  return true;
};

export function findBestStringMatch(
  target: string,
  values: string[],
): string | null {
  if (values.length === 0) return null;

  // Find the best match using string-similarity
  const {bestMatch} = stringSimilarity.findBestMatch(target, values);

  return bestMatch.target;
}

export function findBestStateMatch(
  target: string,
  states: StateDto[],
): StateDto | null {
  if (states.length === 0) return null;

  const stateNames = states
    .map((state) => state.name ?? "")
    ?.filter((value) => !!value);

  const bestMatchStateName = findBestStringMatch(target, stateNames);

  return states.find((state) => state.name === bestMatchStateName) ?? null;
}
