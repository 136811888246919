import {FormikProps} from "formik";
import {UserContactAddressInfo} from "../../../UserProfile/AccountSavedAddresses/components";
import {PageFragments} from "../../../UserProfile/AccountSavedAddresses/pages";
import {ContactAddressDto} from "../../../features/contactAddress/contactAddress-api";
import {SelectedTabEnum} from "./deliveryAddressForm";
import {DeliveryConfiguration} from "../../delivery.module";
import {RequiredField} from "../../../common/hooks/useFormRequiredFields";
import {CountryFilter} from "../../../common/hooks/useGetCountries";

export interface DeliveryAddressFragmentProps {
  handleNext: any;
  pageTitle: any;
  config?: DeliveryConfiguration;
}

export interface UserAddressesFormProps {
  defaultContactAddressId: number | null;
  setDefaultContactAddressId: (id: number) => void;
  contactAddresses: (UserContactAddressInfo & AddressCardValues)[] | null;
  onChangePageState?: (pageState: PageFragments) => void;
  triggerHandleBack?: boolean;
  updateAddresses?: () => void;
  setEditingWidth: (editingWidth: boolean) => void;
}

export interface UserPickupLocationFormProps {
  onChangePageState?: (pageState: PageFragments) => void;
}

export interface DeliveryAddressFormProps {
  pageFragment: PageFragments | null;
  editingAddress: UserContactAddressInfo | null;
  setCurrentDeliveryAddress: (
    currentDeliveryAddress: UserContactAddressInfo & AddressCardValues,
  ) => void;
  setCurrentDeliveryAddressId: (
    currentDeliveryAddressId: number | null,
  ) => void;
  setEditingWidth: (editingWidth: boolean) => void;
  onChangePageState?: (pageState: PageFragments) => void;
  setEditingAddress: (address: UserContactAddressInfo | null) => void;
  setCurrentDeliveryAddressFormDto: (
    contactAddress?: ContactAddressDto | null,
  ) => void;
  getContactAddress: (
    contactAddressId: number,
  ) => Promise<ContactAddressDto | null>;
  openedOnShipRequest?: boolean;
  config?: DeliveryConfiguration;
}

export interface ToAddressComponentProps {
  showMyAddresses: boolean;
  setShowMyAddresses?: (val: boolean) => void;
  setValidationSchema?: (val: any) => void;
  formikProps: FormikProps<DeliveryAddressFormFields>;
  initialActiveCountry: string | null;
  disabled?: boolean;
  isEditingAddress?: boolean;
  showSaveAddressCheckbox?: boolean;
  isDefaultAddress?: boolean;
  setCurrentAddress?: (val: any) => void;
  selectedTab?: SelectedTabEnum;
  phoneNumberDefaultCountryCode?: string;
  requiredFields?: RequiredField[] | null;
  countryFilter?: CountryFilter;
  disableCountrySelect?: boolean;
}

export interface PickupLocationComponentProps {
  formikProps: FormikProps<DeliveryAddressFormFields>;
  initialActiveCountry: string | null;
  initialActiveMarkerId: number | null;
  disabled?: boolean;
  isDefaultAddress?: boolean;
  setValidationSchema?: any;
  selectedTab?: SelectedTabEnum;
  phoneNumberDefaultCountryCode?: string;
  requiredFields?: RequiredField[] | null;
  countryFilter?: CountryFilter;
}

export interface DeliveryAddressFormFields {
  toAddressFirstName: string;
  toAddressLastName: string;
  toAddressPhoneNumber: string;
  toAddressEmail: string;
  toAddressCountryCode: string;
  toAddressCountryName: string;
  toAddressRegionCode: string;
  toAddressRegionName: string;
  toAddressDistrict: string;
  toAddressCity: string;
  toAddressPostalCode: string;
  toAddressStreetName: string;
  toAddressHouseNumber: string;
  toAddressApartment: string;
  toAddressSetAsDefault: boolean;
  toAddressLatitude?: number | null;
  toAddressLongitude?: number | null;
  toAddressContactCategory: ContactCategory;
  countryCustomValues?: any;
  toAddressCompanyName?: string;
  pickupLocationFirstName: string;
  pickupLocationLastName: string;
  pickupLocationPhoneNumber: string;
  pickupLocationEmail: string;
  pickupLocationDistrict: string;
  pickupLocationCountryCode: string;
  pickupLocationCountryName: string;
  pickupLocationContactAddressId: number | null;
  pickupLocationPostOffices: boolean;
  pickupLocationParcelLockers: boolean;
  pickupLocationAddressLine: string;
  pickupLocationCityId?: number | null;
  pickupLocationContactCategory: ContactCategory;
  pickupLocationCompanyName?: string;
}

export type ContactAddressValues = {
  contactAddressId?: number | null;
  contactId?: number | null;
  toAddressFirstName: string | null;
  toAddressLastName: string | null;
  toAddressPhoneNumber: string | null;
  toAddressEmail: string | null;
  toAddressCountryCode: string | null;
  toAddressCountryName: string | null;
  toAddressRegionCode: string | null;
  toAddressRegionName: string | null;
  toAddressDistrict: string | null;
  toAddressCity: string | null;
  toAddressPostalCode: string | null;
  toAddressStreetName: string | null;
  toAddressHouseNumber: string | null;
  toAddressApartment: string | null;
  toAddressSetAsDefault: boolean | null;
  toAddressCompanyName?: string | null;
  toAddressLatitude?: number | null;
  toAddressLongitude?: number | null;
  toAddressContactCategory: ContactCategory;
  countryCustomValues?: any;
  toAddressWithoutApartmentConfirmed?: boolean;
};

export type PickupLocationValues = {
  pickupLocationFirstName: string | null;
  pickupLocationLastName: string | null;
  pickupLocationPhoneNumber: string | null;
  pickupLocationEmail: string | null;
  pickupLocationCountryCode: string | null;
  pickupLocationCountryName: string | null;
  pickupLocationDistrict: string | null;
  pickupLocationContactAddressId: number | null;
  pickupLocationPostOffices: boolean | null;
  pickupLocationParcelLockers: boolean | null;
  pickupLocationAddressLine: string | null;
  pickupLocationContactCategory: ContactCategory;
  pickupLocationCompanyName?: string;
};

export type DeliveryAddressCommandValues = ContactAddressValues &
  PickupLocationValues & {
    passportData?: any;
  };

export type AddressCardValues = {
  name?: string | null;
  contactName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  region?: string | null;
  district?: string | null;
  postalCode?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
};

export enum ContactCategory {
  Individual = "Individual",
  Business = "Business",
}
