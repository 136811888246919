import {createSlice} from "@reduxjs/toolkit";
import {CountryGqlDto} from "../order/order-api";

interface ProhibitedKeywordsSliceState {
  prohibitedKeywords: string[];
  loadedKeywordsCountryCode: string | null;
  country?: CountryGqlDto | null;
}

const initialState: ProhibitedKeywordsSliceState = {
  prohibitedKeywords: [],
  loadedKeywordsCountryCode: null,
  country: null,
};

export const prohibitedKeywordsSlice = createSlice({
  initialState,
  name: "prohibitedKeywordsSlice",
  reducers: {
    setProhibitedKeywords: (state, action) => {
      const {prohibitedKeywords, countryCode, country} = action.payload;
      state.prohibitedKeywords = prohibitedKeywords;
      state.loadedKeywordsCountryCode = countryCode;
      state.country = country;
    },
  },
});

export default prohibitedKeywordsSlice.reducer;

export const {setProhibitedKeywords} = prohibitedKeywordsSlice.actions;
