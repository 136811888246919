import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import {useFormikContext} from "formik";
import React, {useCallback, useMemo, useState} from "react";
import {useMapsLibrary} from "@vis.gl/react-google-maps";
import {parseGeocodeResults} from "../../../utils/address.utils";

type GooglePlacesAutocompleteProps = {
  fieldName: string;
  fieldNameForValue?: string;
  fieldNameForParsedValue?: string;
  minAutocompleteLength?: number;
  placeholder?: string | null | undefined;
  startAdornment?: any;
  onChange?: any;
  onChangeParsed?: any;
  country?: string | string[] | null;
  isDisabled?: boolean;
};

export default function GooglePlacesAutocomplete({
  fieldName,
  fieldNameForValue = fieldName + "Value",
  fieldNameForParsedValue = fieldName + "ParsedValue",
  minAutocompleteLength = 2,
  placeholder = "Find Locations Near...",
  startAdornment,
  onChange,
  onChangeParsed,
  country = ["us"],
  isDisabled = false,
}: GooglePlacesAutocompleteProps) {
  const formik = useFormikContext<any>();

  const googlePlacesLibrary = useMapsLibrary("places");
  const autocompleteService = useMemo(
    () => googlePlacesLibrary && new googlePlacesLibrary.AutocompleteService(),
    [googlePlacesLibrary],
  ) as google.maps.places.AutocompleteService | null;
  const placesService = useMemo(
    () =>
      googlePlacesLibrary &&
      new googlePlacesLibrary.PlacesService(document.createElement("div")),
    [googlePlacesLibrary],
  ) as google.maps.places.PlacesService | null;

  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const handleInputChange = useCallback(
    (event: any) => {
      const input = event?.target?.value;
      if (input && input.length >= minAutocompleteLength) {
        if (autocompleteService) {
          autocompleteService.getPlacePredictions(
            {
              input,
              language: "en",
              types: ["address"],
              region: "us",
              componentRestrictions: {
                country: country,
              },
            },
            (result, status) => {
              if (status == "OK" && result) {
                setOptions(result);
              } else {
                setOptions([]);
              }
            },
          );
        } else {
          setOptions([]);
        }
      }
    },
    [autocompleteService, formik?.values?.toAddressCountryCode],
  );

  const handleSelectChange = useCallback(
    async (_: any, value: google.maps.places.AutocompletePrediction | null) => {
      if (value && !options?.includes(value)) {
        setOptions([...options, value]);
      }
      formik.setFieldValue(fieldName, value);
      if (value?.place_id && placesService) {
        placesService.getDetails(
          {
            placeId: value.place_id,
            language: "en",
            fields: [
              "geometry.location",
              "formatted_address",
              "address_components",
            ],
          },
          (result, status) => {
            if (status === "OK" && result) {
              formik.setFieldValue(fieldNameForValue, result);
              const parsedGeocodeResult = parseGeocodeResults(result);
              formik.setFieldValue(
                fieldNameForParsedValue,
                parsedGeocodeResult,
              );
              if (onChange) onChange(formik, result);
              if (onChangeParsed) onChangeParsed(formik, parsedGeocodeResult);
            }
          },
        );
      }
    },
    [formik, fieldName, fieldNameForValue, onChange],
  );

  return useMemo(() => {
    return (
      <Autocomplete
        autoSelect={true}
        options={options}
        value={formik?.values?.[fieldName]}
        onChange={handleSelectChange}
        getOptionLabel={(option) => option?.description}
        isOptionEqualToValue={(option, value) => {
          return !!option?.place_id && option?.place_id === value?.place_id;
        }}
        filterOptions={(options) => options}
        autoHighlight={true}
        noOptionsText={"No suggestions found"}
        disabled={isDisabled}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={formik?.values?.[fieldName]?.description}
              onChange={handleInputChange}
              placeholder={placeholder ?? "Find Locations Near..."}
              InputProps={{
                ...params.InputProps,

                startAdornment: startAdornment ? (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ) : null,
              }}
            />
          );
        }}
      />
    );
  }, [
    options,
    formik?.values?.[fieldName],
    formik?.values?.[fieldNameForValue],
    handleInputChange,
    isDisabled,
  ]);
}
