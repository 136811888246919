import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Typography, useMediaQuery, useTheme} from "@mui/material";
import {PrintLabelProps} from "../pages/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import {Trans} from "react-i18next";
import printJS from "print-js";
import {toastError} from "../../../common/utils/toastMessages";
import {getCustomValue} from "../../../../utils/helper.utils";
import {useAppSelector} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
  WorkflowExecutionResultDto,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {toast} from "react-hot-toast";
import i18n, {t} from "i18next";
import parse from "html-react-parser";
import dayjs from "dayjs";
import {useModalForm} from "../../../common/hooks/useModalForm";
import {Formik, FormikProps} from "formik";
import PickupForm, {
  PickupFormInitialValues,
  PickupFormValues,
} from "../../../Delivery/Pickup/pickupForm";
import {useCheckAvailabilityWorkflow} from "../../../Delivery/Pickup/hooks/useCheckAvailabilityWorkflow";
import {useReschedulePickupWorkflow} from "../../../Delivery/Pickup/hooks/useReschedulePickupWorkflow";
import {useCancelPickupWorkflow} from "../../../Delivery/Pickup/hooks/useCancelPickupWorkflow";

export default function PrintLabel({
  parcelShipment,
  loadParcelShipment,
}: PrintLabelProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [useWorkflow] = useWorkflowExecutionMutation();
  const lang = i18n.language;
  const dayjsLang = lang === "ua" ? "uk" : lang;

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.sendEmailWorkflowId,
  );

  const documentName = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.parcels?.printLabelDocumentName,
  );

  const fedexAttachmentPropertyName = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.modules?.parcels
        ?.fedexAttachmentPropertyName,
  );

  const email = useAppSelector((state: any) => state.userState?.email);

  const orderDocuments = parcelShipment.orderDocuments?.filter(
    (doc) => doc.documentTemplate?.documentTemplateType === documentName,
  );

  const attachmentDocuments = parcelShipment.documentAttachments?.filter(
    (attachment) =>
      attachment.attachmentId ==
      parcelShipment.customValues?.find(
        (x) => x.key == fedexAttachmentPropertyName,
      )?.value,
  );

  const orderDocument = orderDocuments
    ? orderDocuments[orderDocuments?.length - 1]
    : null;

  const attachmentDocument = attachmentDocuments
    ? attachmentDocuments[0]
    : null;

  const firstMileCarrier = parcelShipment?.firstMileCarrier;
  const isPickupRequest = !!getCustomValue(
    firstMileCarrier?.customValues,
    "pickup_confirmation_code",
  );

  const pickupCheckAvailabilityWorkflowId = getCustomValue(
    firstMileCarrier?.contact?.customValues,
    "pickup_check_availability_workflow_id",
  );
  const pickupRescheduleWorkflowId = getCustomValue(
    firstMileCarrier?.contact?.customValues,
    "pickup_reschedule_request_workflow_id",
  );
  const pickupCancelWorkflowId = getCustomValue(
    firstMileCarrier?.contact?.customValues,
    "pickup_cancel_request_workflow_id",
  );
  const shippingFromContactAddressId =
    parcelShipment?.shipper?.contactAddressId;

  const {
    checkAvailability,
    isLoading,
    isError: isErrorCheckAvailability,
  } = useCheckAvailabilityWorkflow(pickupCheckAvailabilityWorkflowId);
  const {reschedulePickup} = useReschedulePickupWorkflow(
    pickupRescheduleWorkflowId,
  );
  const {cancelPickup} = useCancelPickupWorkflow(pickupCancelWorkflowId);
  const [availableOptions, setAvailableOptions] = React.useState<any>(null);
  const pickupConfirmationCode = getCustomValue(
    firstMileCarrier?.customValues,
    "pickup_confirmation_code",
  );
  const pickupLatestTimestamp = dayjs(
    `${getCustomValue(
      firstMileCarrier?.customValues,
      "pickup_date",
    )} ${getCustomValue(firstMileCarrier?.customValues, "pickup_latest_time")}`,
    "YYYY-MM-DD HH:mm:ss",
  );
  const isPickupTimeExpired = pickupLatestTimestamp?.isBefore(dayjs()) ?? true;
  const canReschedulePickup =
    !!pickupRescheduleWorkflowId &&
    !!pickupConfirmationCode &&
    !isPickupTimeExpired;
  const [isReschedulePickupModalOpen, setIsReschedulePickupModalOpen] =
    React.useState(false);

  const canCancelPickup =
    !!pickupCancelWorkflowId &&
    !!pickupConfirmationCode &&
    !isPickupTimeExpired;
  const [isCancelPickupModalOpen, setIsCancelPickupModalOpen] = useState(false);

  const pickupDate = getCustomValue(
    firstMileCarrier?.customValues,
    "pickup_date",
  );
  const reschedulePickupFormInitialValues: PickupFormValues = {
    pickupDate: pickupDate
      ? dayjs(pickupDate)
      : PickupFormInitialValues.pickupDate,
    readyTime:
      getCustomValue(firstMileCarrier?.customValues, "pickup_ready_time") ??
      PickupFormInitialValues.readyTime,
    latestTime:
      getCustomValue(firstMileCarrier?.customValues, "pickup_latest_time") ??
      PickupFormInitialValues.latestTime,
  };

  const handleCheckAvailability = useCallback(
    (values: PickupFormValues) => {
      if (checkAvailability && shippingFromContactAddressId) {
        checkAvailability({
          contactAddressId: shippingFromContactAddressId,
          dispatchDate: values?.pickupDate
            ? values?.pickupDate?.format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
        })?.then((options) => {
          setAvailableOptions(options ?? []);
        });
      }
      setAvailableOptions([]);
    },
    [checkAvailability, shippingFromContactAddressId],
  );

  const handleReschedulePickupSubmit = useCallback(
    async (values: PickupFormValues) => {
      if (reschedulePickup && parcelShipment?.orderId) {
        reschedulePickup({
          pickupInfo: {
            ...values,
            pickupDate: values?.pickupDate?.format("YYYY-MM-DD"),
          },
          orderId: parcelShipment?.orderId,
        })?.then(() => {
          setIsReschedulePickupModalOpen(false);
          toast.success(t("delivery.pickup.rescheduleSuccess"));
          if (loadParcelShipment) {
            loadParcelShipment().catch((error) => {
              toastError(error);
            });
          }
        });
      }
    },
    [reschedulePickup, shippingFromContactAddressId, loadParcelShipment],
  );

  const handleCancelPickupSubmit = useCallback(() => {
    if (cancelPickup && parcelShipment?.orderId) {
      cancelPickup({
        orderId: parcelShipment?.orderId,
      })?.then((result) => {
        if (!result) {
          return;
        }
        setIsCancelPickupModalOpen(false);
        toast.success(t("delivery.pickup.cancelSuccess"));
        if (loadParcelShipment) {
          loadParcelShipment().catch((error) => {
            toastError(error);
          });
        }
      });
    }
  }, [
    setIsCancelPickupModalOpen,
    cancelPickup,
    parcelShipment?.orderId,
    loadParcelShipment,
  ]);

  useEffect(() => {
    if (handleCheckAvailability && isReschedulePickupModalOpen) {
      handleCheckAvailability(reschedulePickupFormInitialValues);
    }
  }, [handleCheckAvailability, isReschedulePickupModalOpen]);

  const renderForm = useCallback(
    (formikProps: FormikProps<PickupFormValues>) => (
      <Grid container gap={4}>
        <Typography variant="h3">{t("delivery.pickup.pageTitle")}</Typography>
        <PickupForm
          formikProps={formikProps}
          availableOptions={availableOptions}
          handleCheckAvailability={handleCheckAvailability}
          isLoading={isLoading ?? true}
          isError={isErrorCheckAvailability ?? false}
          submitBtnLabel={t("delivery.pickup.submit")}
          onCancel={() => setIsReschedulePickupModalOpen(false)}
        />
      </Grid>
    ),
    [
      isLoading,
      availableOptions,
      handleCheckAvailability,
      isErrorCheckAvailability,
    ],
  );

  const renderReschedulePickupFormik = useCallback(() => {
    return (
      <Formik
        initialValues={reschedulePickupFormInitialValues}
        onSubmit={(values) => {
          handleReschedulePickupSubmit(values);
        }}
      >
        {(formikProps) => renderForm(formikProps)}
      </Formik>
    );
  }, [renderForm]);

  const {renderModal: renderReschedulePickupModal} = useModalForm({
    isOpen: isReschedulePickupModalOpen,
    content: renderReschedulePickupFormik(),
  });

  const {renderModal: renderCancelPickupModal} = useModalForm({
    isOpen: isCancelPickupModalOpen,
    text: t("delivery.pickup.cancelConfirmation"),
    handleConfirm: handleCancelPickupSubmit,
    handleCancel: () => setIsCancelPickupModalOpen(false),
  });

  const getPickupInstruction = useCallback(() => {
    const pickupInstructionCustomValue = `pickup_instruction_${lang}`;
    const pickupInstruction = getCustomValue(
      firstMileCarrier?.contact?.customValues,
      pickupInstructionCustomValue,
    );

    if (!pickupInstruction) {
      return null;
    }

    const pickupDate = getCustomValue(
      firstMileCarrier?.customValues,
      "pickup_date",
    );
    const pickupDateFormatted =
      dayjs(pickupDate).locale(dayjsLang).format("dddd, MMMM D") ?? "";

    const pickupReadyTime = getCustomValue(
      firstMileCarrier?.customValues,
      "pickup_ready_time",
    );
    const pickupReadyTimeFormatted =
      dayjs(pickupReadyTime, "HH:mm:ss").format("h:mm A") ?? "";

    const pickupLatestTime = getCustomValue(
      firstMileCarrier?.customValues,
      "pickup_latest_time",
    );
    const pickupLatestTimeFormatted =
      dayjs(pickupLatestTime, "HH:mm:ss").format("h:mm A") ?? "";

    const pickupInstructionWithTime = pickupInstruction
      ?.replaceAll("{{ date }}", pickupDateFormatted)
      ?.replaceAll("{{ ready_time }}", pickupReadyTimeFormatted)
      ?.replaceAll("{{ latest_time }}", pickupLatestTimeFormatted);

    return parse(pickupInstructionWithTime);
  }, [lang, dayjsLang, firstMileCarrier, getCustomValue, dayjs]);

  const getLabelInstruction = () => {
    const labelInstructionCustomValue = `portal_label_instruction_${lang}`;
    const carriersWithLabelInstruction = parcelShipment.orderEntities?.filter(
      (x) =>
        x.entityType === "Carrier" &&
        getCustomValue(x.contact?.customValues, labelInstructionCustomValue) !=
          null,
    );
    const labelInstruction =
      carriersWithLabelInstruction && carriersWithLabelInstruction.length > 0
        ? getCustomValue(
            carriersWithLabelInstruction[0]?.contact?.customValues,
            labelInstructionCustomValue,
          )
        : null;

    if (!labelInstruction) {
      return null;
    }
    return parse(labelInstruction);
  };

  const printLabel = () => {
    printJS({
      printable:
        orderDocument?.linkToDocument ||
        attachmentDocument?.presignedFileUri ||
        "",
      type: "pdf",
    });
  };

  const downloadDocument = () => {
    fetch(
      orderDocument?.linkToDocument ||
        attachmentDocument?.presignedFileUri ||
        "",
      {
        referrerPolicy: "no-referrer",
      },
    )
      .then((response) => {
        if (!response.ok) {
          toastError("Failed to download document.");
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "document";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(() => {
        toastError("Failed to download document.");
      });
  };

  const sendToEmail = () => {
    let variables = {};
    if (attachmentDocument) {
      variables = {
        recipientEmail: email,
        attachmentId: attachmentDocument.attachmentId,
        orderId: parcelShipment.orderId,
        localization: lang,
      };
    } else if (orderDocument) {
      variables = {
        recipientEmail: email,
        orderDocumentId: orderDocument.orderDocumentId,
        orderId: parcelShipment.orderId,
        localization: lang,
      };
    }
    const commandArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId,
      values: {variables},
    };
    useWorkflow(commandArgs)
      .then((res) => {
        const result = res as WorkflowExecutionResultDto;
        if (result.error) {
          toastError("Failed to send document.");
        } else {
          toast.success(t("toasts.labelSuccessSent"));
        }
      })
      .catch(() => {
        toastError("Failed to send document.");
      });
  };

  return (
    ((orderDocument || attachmentDocument) && (
      <Card
        sx={
          isDesktop
            ? {
                border: "1px solid #D8E6FB",
                boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
                borderRadius: "16px",
                marginBottom: "25px",
                background: "#F5F9FF",
              }
            : {
                border: "none",
                boxShadow: "none",
              }
        }
      >
        <Grid
          container
          columns={{xs: 6, md: 5}}
          display={"flex"}
          justifyContent={"space-between"}
          m={{xs: 0, md: 4, lg: 4}}
          sx={{
            marginBottom: {xs: 4},
          }}
        >
          <Typography
            variant="body4"
            sx={{
              textTransform: "none",
              width: "100%",
            }}
          >
            {isPickupRequest
              ? getPickupInstruction()
              : getLabelInstruction() ?? (
                  <Trans
                    i18nKey={
                      "parcelShipments.shipmentDetails.printLabelDescription"
                    }
                  >
                    Print the label and drop your package at one of our drop off
                    locations
                  </Trans>
                )}
          </Typography>
          <Grid
            container
            display={"flex"}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              color={"secondary"}
              variant={"contained"}
              sx={{
                paddingX: 5,
                textTransform: "none",
                lineHeight: "18px",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "5px",
              }}
              onClick={printLabel}
            >
              <Trans i18nKey={"parcelShipments.shipmentDetails.printLabel"}>
                Print Label
              </Trans>
            </Button>
          </Grid>
          <Grid
            container
            display={"flex"}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              color={"inherit"}
              variant={"contained"}
              sx={{
                paddingX: 5,
                textTransform: "none",
                lineHeight: "18px",
                background: "#D8E6FB",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "5px",
              }}
              onClick={downloadDocument}
            >
              <Trans i18nKey={"parcelShipments.shipmentDetails.download"}>
                Download
              </Trans>
            </Button>
          </Grid>
          <Grid
            container
            display={"flex"}
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              color={"inherit"}
              variant={"contained"}
              sx={{
                paddingX: 5,
                textTransform: "none",
                lineHeight: "18px",
                background: "#D8E6FB",
                marginTop: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                marginBottom: "5px",
              }}
              onClick={sendToEmail}
            >
              <Trans i18nKey={"parcelShipments.shipmentDetails.send"}>
                Send to My Email
              </Trans>
            </Button>
          </Grid>
          {canReschedulePickup && (
            <Grid
              container
              display={"flex"}
              sx={{
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <Button
                color={"inherit"}
                variant={"contained"}
                sx={{
                  paddingX: 5,
                  textTransform: "none",
                  lineHeight: "18px",
                  background: "#D8E6FB",
                  marginTop: "20px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  marginBottom: "5px",
                }}
                onClick={() => {
                  setIsReschedulePickupModalOpen(true);
                }}
              >
                <Trans
                  i18nKey={"parcelShipments.shipmentDetails.reschedulePickup"}
                >
                  Reschedule
                </Trans>
              </Button>
              {renderReschedulePickupModal()}
            </Grid>
          )}
          {canCancelPickup && (
            <Grid
              container
              display={"flex"}
              sx={{
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <Button
                color={"inherit"}
                variant={"contained"}
                sx={{
                  paddingX: 5,
                  textTransform: "none",
                  lineHeight: "18px",
                  background: "#D8E6FB",
                  marginTop: "20px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  marginBottom: "5px",
                }}
                onClick={() => {
                  setIsCancelPickupModalOpen(true);
                }}
              >
                <Trans i18nKey={"parcelShipments.shipmentDetails.cancelPickup"}>
                  Cancel Pickup
                </Trans>
              </Button>
              {renderCancelPickupModal()}
            </Grid>
          )}
        </Grid>
      </Card>
    )) || <></>
  );
}
