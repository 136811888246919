import {useCallback, useEffect, useMemo} from "react";
import {getPortalNameFromDomain} from "../../../common/OrganizationConfig/useOrganizationConfig";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {setProhibitedKeywords} from "../../../features/prohibitedKeywords/prohibitedKeywords-slice";

export const useCountryProhibitedKeywordsValidation = () => {
  const portalName = useMemo(() => {
    return process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();
  }, [getPortalNameFromDomain, process.env.REACT_APP_PORTAL_NAME]);

  const {currentOrder} = useDeliveryHelper();
  const shoppingDestinationCountryCode = useMemo(() => {
    return currentOrder?.consigneeAddressValues?.countryCode;
  }, [currentOrder?.consigneeAddressValues?.countryCode]);

  const parcelsDestinationCountryCode = useSelector(
    (state: RootState) =>
      state?.parcelState?.order?.consigneeAddressValues?.countryCode,
  );

  const countryCode = useMemo(() => {
    if (portalName)
      switch (portalName) {
        case "portal":
          return shoppingDestinationCountryCode;
        case "parcels":
          return parcelsDestinationCountryCode;
        default:
          console.error("Invalid portal name", portalName);
          return "";
      }
    return "";
  }, [
    portalName,
    shoppingDestinationCountryCode,
    parcelsDestinationCountryCode,
  ]);

  const getCountryProhibitedItemsWorkflowId = useSelector(
    (state: RootState) => {
      return state?.organizationConfigState?.workflows
        ?.getCountryProhibitedItemsWorkflowId;
    },
  );

  const [runGetCountryProhibitedItemsWorkflow, {isLoading, isSuccess}] =
    useWorkflowExecutionMutation();

  const {prohibitedKeywords, loadedKeywordsCountryCode, country} = useSelector(
    (state: RootState) => state?.prohibitedKeywordsState,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      countryCode &&
      getCountryProhibitedItemsWorkflowId &&
      !isLoading &&
      countryCode !== loadedKeywordsCountryCode
    ) {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: getCountryProhibitedItemsWorkflowId,
        values: {
          variables: {
            countryCode,
          },
        },
      };

      runGetCountryProhibitedItemsWorkflow(executeWorkflowApiArgs)
        .then((response: any) => {
          const prohibitedKeywords =
            response?.data?.outputs?.countryProhibitedItemsKeywords
              ?.split(",")
              ?.map((keyword: string) => keyword?.trim()?.toLowerCase()) ?? [];

          dispatch(
            setProhibitedKeywords({
              prohibitedKeywords,
              countryCode,
              country: response?.data?.outputs?.country,
            }),
          );
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }, [
    countryCode,
    getCountryProhibitedItemsWorkflowId,
    loadedKeywordsCountryCode,
    isLoading,
    dispatch,
  ]);

  const validateText = useCallback(
    (text: any): boolean => {
      if (text && prohibitedKeywords && typeof text === "string") {
        const words = text.split(/\W+/); // Split by non-word characters
        const anyProhibitedWordsFound = words.some((word) =>
          prohibitedKeywords.includes(word.toLowerCase()),
        );
        return !anyProhibitedWordsFound;
      }
      return true;
    },
    [prohibitedKeywords],
  );

  const getInvalidKeywordsFromText = useCallback(
    (text: any): string[] => {
      if (text && prohibitedKeywords && typeof text === "string") {
        const words = text.split(/\W+/); // Split by non-word characters
        return words.filter((word) =>
          prohibitedKeywords.includes(word.toLowerCase()),
        );
      }
      return [];
    },
    [prohibitedKeywords],
  );

  return {validateText, getInvalidKeywordsFromText, country};
};
